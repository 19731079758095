import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import './Play.css';

class Play extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      loading: true,
      copied: false 
    };
  }

  render() {
    return (
        <Row>
            <Col md={8} className="offset-md-2">
                <Card> 
                  <div class="card-body">
                    <h3>Play</h3>
                    <p class="card-text">
                        Whether you have come to fight, socialize, dominate, or explore - prepare to discover a world where the only limit is your imagination.
                        We hope that your experience on MeepCraft will inspire you to create something amazing! We can't wait to share the experience with you.
                        Thanks for joining our community.
                    </p>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">Server Address</span>
                      </div>
                      <input type="text" class="form-control" placeholder="Username" value="meepcraft.com" />
                    </div>
                    <CopyToClipboard text={"meepcraft.com"}
                      onCopy={() => this.setState({copied: true})}>
                      <button type="button" class="btn btn-dark">Click to Copy</button>
                    </CopyToClipboard>
                    {this.state.copied ? 
                      (<span>&nbsp;&nbsp;&nbsp;
                        <span class="alert alert-success" role="alert">Copied!</span>
                      </span>)
                      : null}
                    <hr />
                    <h6>STEP 1</h6>
                    <p class="card-text">
                      Open Minecraft and select <span class="green">Multiplayer</span>
                    </p>
                    <h6>STEP 2</h6>
                    <p class="card-text">
                      Click <span class="green">Add Server</span>
                    </p>
                    <h6>STEP 3</h6>
                    <p class="card-text">
                      Enter <span class="green">MeepCraft</span> as the Server Name and <span class="green">meepcraft.com</span> as the Server Address
                    </p>
                  </div>
                </Card>
            </Col>
        </Row>
    );
  }
}

export default Play;
