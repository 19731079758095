import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import './Vote.css';

class Vote extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      loading: true,
      copied: false 
    };
  }

  render() {
    return (
        <Row className="vote">
            <Col md={8} className="offset-md-2">
                <Card> 
                  <div class="card-body">
                    <h3>Vote</h3>
                    <p class="card-text">
                        From the beginning, the Minecraft community has been plagued with bad servers. 
                        We've set out to create a unique experience that is a step ahead of the rest. 
                        By voting for MeepCraft on these Minecraft server lists, you can
                        let the world know that there is hope out there. Together, we will save Minecraft, 
                        one vote at a time.
                    </p>
                    <div class="votecontainer">
                    <p><a class="vote" href="http://topg.org/Minecraft/in-379200#.UwE7jvldV8E" target="_blank">
                      <button type="button" class="btn btn-dark">Vote on TopG.org&nbsp;&nbsp;<span class="green">+ 1,000 Meebles</span></button>
                    </a></p>
                    <p><a class="vote" href="http://minecraftservers.org/vote/79375" target="_blank">
                      <button type="button" class="btn btn-dark">Vote on MinecraftServers.org&nbsp;&nbsp;<span class="green">+ 1,000 Meebles</span></button>
                    </a></p>
                    <p><a class="vote" href="http://minecraft-mp.com/server/61/vote/" target="_blank">
                      <button type="button" class="btn btn-dark">Vote on Minecraft-MP.com&nbsp;&nbsp;<span class="green">+ 1,000 Meebles</span></button>
                    </a></p>
                    <p><a class="vote" href="http://minecraft-server-list.com/server/26122/vote/" target="_blank">
                      <button type="button" class="btn btn-dark">Vote on Minecraft-Server-List.com&nbsp;&nbsp;<span class="green">+ 1,000 Meebles</span></button>
                    </a></p>
                    </div>
                    <span>
                    <hr/>
                    <h4>Vote Crates</h4>
                    <p>Each time you vote, there's a 20% chance that you will receive a <span class="green">vote key</span>. Vote keys allow you to unlock special crates in the game.</p>
                    <table border="0" cellpadding="0" cellspacing="0">
                      <tbody><tr>
                        <td><img src="/assets/coal-icon.png" />
                        <p><b class="green">A 10% chance...</b>
                        <br/>
                        <em>A Custom Disguise Note</em><br/><em></em><br/></p></td>

                        <td><img src="/assets/iron-icon.png" />
                        <p><b class="green">A 30% chance...</b>
                        <br/>
                        <em>2,500 Meebles</em><br/><em> 5,000 Meebles</em><br/><em> </em><br/><em></em></p></td>
                        
                        <td><img src="/assets/gold-icon.png" />
                        <p><b class="green">A 5% chance...</b>
                        <br/>
                        <em>A Vote Shovel #1 or #2 Voucher</em><br/><em> A Vote Axe Voucher</em><br/><em> A Vote Armor Voucher</em><br/><em></em></p></td>	
                      </tr>

                      <tr>
                        <td><img src="/assets/emerald-icon.png" />
                        <p><b class="green">A 1% chance...</b>
                        <br/>
                        <em>50,000 Meebles</em><br/><em> A Vote Pickaxe #1 or #2</em><br/><em></em><br/></p></td>

                        <td><img src="/assets/diamond-icon.png" />
                        <p><b class="green">A 0.5% chance...</b>
                        <br/>
                        <em>A Glass Pickaxe</em><br/><em> 100,000 Meebles</em><br/><em> Grappling Hook Voucher</em><br/><em>Type X Tool</em></p></td>

                        <td><img src="/assets/computer-icon.png" />
                        <p><b class="green">An Unknown chance...</b>
                        <br/>
                        <em>An Endermite Spawner</em><br/><em></em><br/></p></td>

                      </tr>
                    </tbody></table>


                    </span>
                  </div>
                </Card>
            </Col>
        </Row>
    );
  }
}

export default Vote;
