import React, { useState, useContext } from 'react';
import { Modal, Form } from 'react-bootstrap';

import {
  dateInputsFunction,
  timeInputsFunction,
  contactInfoFunction
} from './utils';
import { createModalFooter, createModalHeader } from '../../utils';

import './Details.css';

/**
 * The last part of the flow
 * It grabs final relevant data such as email, and the persons name
 * One last important piece of info is needed here for the quote
 * calculation which is the amount of hours that the user needs
 * the vehicles for (this is for the hourly rental).
 */
export default function Details({ changeSection, Context }) {
  const { dispatch } = useContext(Context);
  // Submit state

  // State for each form control
  // Date form controls
  const today = new Date();
  let tomorrow = new Date();
  let twoDaysFromNow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  twoDaysFromNow.setDate(tomorrow.getDate() + 1);
  const [startDate, changeStartDate] = useState(
    new Date(tomorrow).toDateInputValue()
  );
  const [endDate, changeEndDate] = useState(
    new Date(tomorrow).toDateInputValue()
  );

  // time form controls
  const [pickUpTime, changePickUpTime] = useState('06:00');
  const [amountOfHours, changeAmountOfHours] = useState(4);

  // contact info form controls
  const [firstName, changeFirstName] = useState('');
  const [lastName, changeLastName] = useState('');
  const [email, changeEmail] = useState('');
  const [confirmEmail, changeConfirmEmail] = useState('');
  const [phoneNumber, changePhoneNumber] = useState('');
  const [company, changeCompany] = useState('');

  return (
    <div className="details">
      <Form
        onSubmit={e => {
          e.preventDefault();
          dispatch({
            type: 'update',
            payload: {
              startDate,
              endDate,
              pickUpTime,
              amountOfHours,
              firstName,
              lastName,
              email,
              confirmEmail,
              phoneNumber,
              company
            }
          });
          changeSection('quote');
        }}
      >
        {createModalHeader('Details', changeSection, 'vehicleType')}
        <Modal.Body>
          {dateInputsFunction(
            startDate,
            changeStartDate,
            endDate,
            changeEndDate
          )}
          {timeInputsFunction(
            pickUpTime,
            changePickUpTime,
            amountOfHours,
            changeAmountOfHours
          )}
          {contactInfoFunction(
            firstName,
            changeFirstName,
            lastName,
            changeLastName,
            email,
            changeEmail,
            confirmEmail,
            changeConfirmEmail,
            phoneNumber,
            changePhoneNumber,
            company,
            changeCompany
          )}
        </Modal.Body>
        {createModalFooter()}
      </Form>
    </div>
  );
}
