import React, { useState, useContext, useEffect } from 'react';
import { Container, Row, Col, Modal, Form } from 'react-bootstrap';

import {
  imagesFunction,
  locationsSelectedFunction,
  inputFunction
} from './utils';
import { createModalFooter, createModalHeader } from '../../utils';

import './TripLocation.css';

/**
 * This is one of the following components referenced in TripType.js
 * This function creates either select-able images with text that, when clicked
 * narrow down options such as this:
    "airportTransferSFO": "169",
    "airportTransferOAK": "210",
    "airportTransferSJC": "262",
 * and this:
    "transfersSanJose": "",
    "transfersSantaCruzNapa": "",
    "transfersSacramento": "",
    "transfersMonterey": ""
  It will return the location the user wants to go to.

  And inputs to find the location that users are going to/from, which is handled
  with the Google places API, filtering the address that the user is searching

  The data obtained here is the Pick Up location, drop off location, and (if
  the user is doing an airport transfer or city transfer) the city
*/
export default function TripLocation({ changeSection, Context }) {
  const { state, dispatch } = useContext(Context);

  // Needed for what images are to be rendered
  const tripTypeSelection = state.tripTypeSelection;

  const [finishedInputting, setAsFinished] = useState(false);

  // Address
  // onChange for the input
  const [pickUpLocation, changePickUpLocation] = useState('');
  // Toggled when user clicks an option available
  const [pickUpLocationSelected, togglePickUpLocationSelected] = useState(
    false
  );
  // Set after each selection (toggle)
  const [pickUpLocationSelection, changePickUpLocationSelection] = useState('');

  const [dropOffLocation, changeDropOffLocation] = useState('');
  const [dropOffLocationSelected, toggleDropOffLocationSelected] = useState(
    false
  );
  const [dropOffLocationSelection, changeDropOffLocationSelection] = useState(
    ''
  );
  // Stops state, there can be an unlimited amount
  const [stopsSelected, changeStopsSelected] = useState([]);
  const [stopSelected, changeStopSelected] = useState('');
  const [wasStopSelected, toggleWasStopSelected] = useState(false);
  const [stopSelection, changeStopSelection] = useState('');

  // For the next section, a city (location is chosen)
  const [locationSelected, changeLocationSelected] = useState(null);
  // Image src and alt state
  const [imgSrcSelected, changeImgSrcSelected] = useState(null);
  const [imgAltSelected, changeImgAltSelected] = useState(null);

  // Certain state is shown only during these options (what city or airport they are going to)
  const checkIfTransfer =
    tripTypeSelection === 'City Transfer' ||
    tripTypeSelection === 'Airport Transfer';

  useEffect(() => {
    // This is checked for all options
    const checkAddresses =
      pickUpLocationSelected === true && dropOffLocationSelected === true;
    // locationSelected relates to the city or airport the user selects
    if (
      (checkIfTransfer && checkAddresses && locationSelected) ||
      (!checkIfTransfer && checkAddresses)
    ) {
      setAsFinished(true);
    } else {
      // This runs in case one of the options is deleted (so no data associated)
      setAsFinished(false);
    }
  }, [locationSelected, pickUpLocationSelected, dropOffLocationSelected]);

  const clientTypeSelection = state.clientTypeSelection;

  return (
    <div className="trip-location">
      <Form
        onSubmit={e => {
          e.preventDefault();
          dispatch({
            type: 'update',
            payload: {
              locationSelected,
              tripLocationImg: imgSrcSelected,
              tripLocationAlt: imgAltSelected,
              pickUpLocationSelection,
              dropOffLocationSelection,
              stopsSelected
            }
          });
          changeSection('amountOfPeople');
        }}
      >
        {createModalHeader(
          'Where are you heading?',
          changeSection,
          null,
          () => {
            if (clientTypeSelection === 'Wedding') {
              changeSection('clientType');
            } else {
              changeSection('tripType');
            }
          }
        )}
        <Modal.Body>
          {checkIfTransfer &&
            imagesFunction(
              tripTypeSelection,
              locationSelected,
              changeLocationSelected,
              changeImgSrcSelected,
              changeImgAltSelected
            )}
          <Container className="inputted-locations">
            <Row>
              <Col>
                {inputFunction(
                  {
                    location: pickUpLocation,
                    locationSelected: pickUpLocationSelected,
                    locationSelection: pickUpLocationSelection
                  },
                  {
                    changeLocation: changePickUpLocation,
                    toggleLocationSelected: togglePickUpLocationSelected,
                    changeLocationSelection: changePickUpLocationSelection
                  },
                  {
                    describedBy: 'pick up location',
                    controlId: 'pickUpLocation',
                    placeholder: 'Pick up location...',
                    label: 'PICK UP LOCATION'
                  }
                )}
                {inputFunction(
                  {
                    stopsSelected,
                    location: stopSelected,
                    locationSelected: wasStopSelected,
                    locationSelection: stopSelection
                  },
                  {
                    changeLocation: changeStopSelected,
                    toggleLocationSelected: toggleWasStopSelected,
                    changeLocationSelection: changeStopSelection,
                    changeStopsSelected
                  },
                  {
                    describedBy: 'stop selection',
                    controlId: 'stopSelection',
                    placeholder: 'Input a stop in between...',
                    label: 'STOP SELECTION'
                  }
                )}
                {inputFunction(
                  {
                    location: dropOffLocation,
                    locationSelected: dropOffLocationSelected,
                    locationSelection: dropOffLocationSelection
                  },
                  {
                    toggleLocationSelected: toggleDropOffLocationSelected,
                    changeLocation: changeDropOffLocation,
                    changeLocationSelection: changeDropOffLocationSelection
                  },
                  {
                    describedBy: 'drop off location',
                    controlId: 'dropOffLocation',
                    placeholder: 'Drop off location...',
                    label: 'DROP OFF LOCATION'
                  }
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {locationsSelectedFunction(
                  {
                    pickUpLocationSelected,
                    pickUpLocationSelection,
                    dropOffLocationSelected,
                    dropOffLocationSelection,
                    wasStopSelected,
                    stopsSelected
                  },
                  {
                    changePickUpLocationSelection,
                    togglePickUpLocationSelected,
                    changeDropOffLocationSelection,
                    toggleDropOffLocationSelected,
                    changeStopsSelected,
                    toggleWasStopSelected
                  }
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        {createModalFooter(finishedInputting)}
      </Form>
    </div>
  );
}
