import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import './Video.css';

export default function Video() {
  const [loading, setLoadingToFalse] = useState(true);

  return (
    <div className="main-video">
      <div className="video-container">
        
        <ReactPlayer
          className="react-player"
          url='https://meepcraft-video.s3.amazonaws.com/default.m3u8'
          playing
          preload="true"
          loop
          config={{ file: { attributes: {
            autoPlay: true,
            muted: true
          }}}}
          onBufferEnd={() => setLoadingToFalse(false)}
        />
      </div>
    </div>
  );
}
