import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import './Discord.css';

class Discord extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  render() {
    return (
        <Row className="discord">
            <Col md={8} className="offset-md-2">
                <Card> 
                  <div class="card-body">
                    <h3>Discord</h3>
                    <p class="card-text">
                    It's time to ditch Skype and TeamSpeak. 
Get involved with the MeepCraft community like never before by joining our Discord. It's an all-in-one voice and text chat for players that's free, secure, and works on both your desktop and phone.
                    </p>
                    <a href="https://discord.gg/h33wMwy" target="_blank"><button type="button" class="btn btn-dark">Connect</button></a>
                    <hr />
                    <iframe src="https://discordapp.com/widget?id=379355618495954944&theme=dark" allowtransparency="true" frameborder="0"></iframe>
                  </div>
                </Card>
            </Col>
        </Row>
    );
  }
}

export default Discord;
