import React, { useState, createContext, useReducer, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import ClientType from './Sections/ClientType/ClientType';
import TripType from './Sections/TripType/TripType';
import TripLocation from './Sections/TripLocation/TripLocation';
import AmountOfPeople from './Sections/AmountOfPeople/AmountOfPeople';
import VehicleType from './Sections/VehicleType/VehicleType';
import Details from './Sections/Details/Details';
import Quote from './Sections/Quote/Quote';

import { fetchData } from './utils';
import { appReducer } from './reducer';

import './StartQuote.css';

// Context will allow me to share state among all children of Context.Provider
// It is done here to pass the multi-step form data between each child
// so it remembered each time the user goes forwards (next step) and backwards (back btn)
const Context = createContext();

export default function StartQuote({ hide }) {
  const [state, dispatch] = useReducer(appReducer, {});
  const [currentSection, changeSection] = useState('clientType');

  useEffect(() => {
    fetchData(dispatch);
  }, []);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <Modal show={true} onHide={hide} className="main-modal">
        {currentSection === 'clientType' && (
          <ClientType changeSection={changeSection} Context={Context} />
        )}
        {currentSection === 'tripType' && (
          <TripType changeSection={changeSection} Context={Context} />
        )}
        {currentSection === 'tripLocation' && (
          <TripLocation changeSection={changeSection} Context={Context} />
        )}
        {currentSection === 'amountOfPeople' && (
          <AmountOfPeople changeSection={changeSection} Context={Context} />
        )}
        {currentSection === 'vehicleType' && (
          <VehicleType changeSection={changeSection} Context={Context} />
        )}
        {currentSection === 'details' && (
          <Details changeSection={changeSection} Context={Context} />
        )}
        {currentSection === 'quote' && (
          <Quote changeSection={changeSection} Context={Context} />
        )}
      </Modal>
    </Context.Provider>
  );
}
