import React, { useState, useContext, useEffect } from 'react';
import { Modal, Form, FormControl } from 'react-bootstrap';

import { onKeyDown } from './utils';
import { createModalHeader, createModalFooter } from '../../utils';

import './AmountOfPeople.css';

/**
 * This is relevant to all options,
 * it simply grabs how many people the user is
 * planning to take on their trip
 */
export default function AmountOfPeople({ changeSection, Context }) {
  const { state, dispatch } = useContext(Context);

  const [finishedInputting, setAsFinished] = useState(false);

  // For the next section, a city (location is chosen)
  const [amountOfPeople, changeAmountOfPeople] = useState(
    state.amountOfPeople || 100
  );

  useEffect(() => {
    // Three possibilities to ignore
    if (
      amountOfPeople !== null &&
      amountOfPeople !== '0' &&
      amountOfPeople !== ''
    ) {
      setAsFinished(true);
    } else {
      setAsFinished(false);
    }
  }, [amountOfPeople]);

  return (
    <div className="amount-of-people">
      <Form
        onSubmit={e => {
          e.preventDefault();
          dispatch({
            type: 'update',
            payload: { amountOfPeople }
          });
          changeSection('vehicleType');
        }}
      >
        {createModalHeader(
          'How many people are being brought?',
          changeSection,
          null,
          () => {
            changeSection('tripLocation');
          }
        )}
        <Modal.Body>
          {/* Both are used for stylistic reasons. */}
          <FormControl
            type="range"
            className="custom-range"
            value={amountOfPeople}
            onChange={e => changeAmountOfPeople(e.target.value)}
            min={10}
            max={500}
          />
          <FormControl
            type="number"
            value={amountOfPeople}
            onChange={e => changeAmountOfPeople(e.target.value)}
            onKeyDown={onKeyDown}
            min={1}
          />
        </Modal.Body>
        {createModalFooter(finishedInputting)}
      </Form>
    </div>
  );
}
