import React from 'react';
import { Form, FormControl, Col, Row } from 'react-bootstrap';

import './Details.css';

// This adds a new prototype that turns milliseconds into a date string
// readable by the date input (to set a default value)
// eslint-disable-next-line no-extend-native
Date.prototype.toDateInputValue = function() {
  var local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};

/**
 * Form controls for user firstName and lastName inputs
 * @param {String} firstName State
 * @param {Function} changeFirstName Hook
 * @param {String} lastName State
 * @param {Function} changeLastName Hook
 * @return <Row>...inputs...</Row>
 */
function nameInputsFunction(
  firstName,
  changeFirstName,
  lastName,
  changeLastName
) {
  return (
    <Row className="name-inputs">
      <Col sm={6}>
        <Form.Group controlId="firstName">
          <FormControl
            required
            type="text"
            name="firstName"
            placeholder="First Name"
            aria-label="First Name"
            aria-describedby="firstName"
            value={firstName}
            onChange={e => changeFirstName(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group controlId="lastName">
          <FormControl
            type="text"
            name="lastName"
            placeholder="Last Name"
            aria-label="Last Name"
            aria-describedby="lastName"
            value={lastName}
            onChange={e => changeLastName(e.target.value)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
}

/**
 * Form controls for user email inputs
 * @param {String} email State
 * @param {Function} changeEmail Hook
 * @param {String} confirmEmail State
 * @param {Function} changeConfirmEmail Hook
 * @return <Row>...inputs...</Row>
 */
function emailInputsFunction(
  email,
  changeEmail,
  confirmEmail,
  changeConfirmEmail
) {
  return (
    <Row className="name-inputs">
      <Col sm={6}>
        <Form.Group controlId="email">
          <FormControl
            required
            type="email"
            name="email"
            placeholder="Email"
            aria-label="Email"
            aria-describedby="email"
            value={email}
            onChange={e => changeEmail(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group controlId="confirmEmail">
          <FormControl
            required
            type="email"
            name="confirmEmail"
            placeholder="Confirm Email"
            aria-label="Confirm Email"
            aria-describedby="confirmEmail"
            value={confirmEmail}
            onChange={e => changeConfirmEmail(e.target.value)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
}

/**
 * Form controls for user phone number and company name inputs
 * @param {String} phoneNumber State
 * @param {Function} changePhoneNumber Hook
 * @param {String} company State
 * @param {Function} changeCompany Hook
 * @return <Row>...inputs...</Row>
 */
function phoneAndCompanyInputsFunction(
  phoneNumber,
  changePhoneNumber,
  company,
  changeCompany
) {
  return (
    <Row className="name-inputs">
      <Col sm={6}>
        <Form.Group controlId="phoneNumber">
          <FormControl
            required
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            aria-label="Phone Number"
            aria-describedby="phoneNumber"
            value={phoneNumber}
            onChange={e => changePhoneNumber(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group controlId="company">
          <FormControl
            type="text"
            name="company"
            placeholder="Company"
            aria-label="Company"
            aria-describedby="company"
            value={company}
            onChange={e => changeCompany(e.target.value)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
}

/**
 * @return An HTML wrapper around multiple inputs
 */
export function contactInfoFunction(
  firstName,
  changeFirstName,
  lastName,
  changeLastName,
  email,
  changeEmail,
  confirmEmail,
  changeConfirmEmail,
  phoneNumber,
  changePhoneNumber,
  company,
  changeCompany
) {
  return (
    <div className="contact-info inputs-border">
      <Form.Label>CONTACT INFORMATION</Form.Label>
      {nameInputsFunction(firstName, changeFirstName, lastName, changeLastName)}
      {emailInputsFunction(
        email,
        changeEmail,
        confirmEmail,
        changeConfirmEmail
      )}
      {phoneAndCompanyInputsFunction(
        phoneNumber,
        changePhoneNumber,
        company,
        changeCompany
      )}
    </div>
  );
}

/**
 * Another simple inputs function
 */
export function timeInputsFunction(
  pickUpTime,
  changePickUpTime,
  amountOfHours,
  changeAmountOfHours
) {
  return (
    <Row className="time-inputs">
      <Col sm={6}>
        <Form.Group controlId="pickUpTime" className="inputs-border">
          <Form.Label>PICK-UP TIME</Form.Label>
          <FormControl
            required
            type="time"
            name="pickUpTime"
            aria-label="Pick-up time"
            aria-describedby="pickUpTime"
            value={pickUpTime}
            onChange={e => changePickUpTime(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group controlId="amountOfHours" className="inputs-border">
          <Form.Label>Amount of Hours</Form.Label>
          <FormControl
            required
            type="number"
            name="amountOfHours"
            aria-label="Amount of hours"
            aria-describedby="amountOfHours"
            value={amountOfHours}
            onChange={e => changeAmountOfHours(e.target.value)}
            min="4"
          />
        </Form.Group>
      </Col>
    </Row>
  );
}

/**
 * Another simple inputs function
 */
export function dateInputsFunction(
  startDate,
  changeStartDate,
  endDate,
  changeEndDate
) {
  return (
    <Row className="date-inputs">
      <Col sm={6}>
        <Form.Group controlId="startDate" className="inputs-border">
          <Form.Label>PICK-UP DATE</Form.Label>
          <FormControl
            required
            type="date"
            name="startDate"
            aria-label="Start Date"
            aria-describedby="startDate"
            value={startDate}
            onChange={e => changeStartDate(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group controlId="endDate" className="inputs-border">
          <Form.Label>DROP-OFF DATE</Form.Label>
          <FormControl
            type="date"
            name="endDate"
            aria-label="End Date"
            aria-describedby="endDate"
            value={endDate}
            onChange={e => changeEndDate(e.target.value)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
}
