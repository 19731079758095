/**
 * Prevent's user from inputting 0 or a negative number or a number that is too large
 * @param {HTMLEvent} e 
 * @return Boolean based upon if the user is inputting correctly
 */
export const onKeyDown = function(e) {
  if (
    !(
      (e.keyCode > 95 && e.keyCode < 106) ||
      (e.keyCode > 47 && e.keyCode < 58) ||
      e.keyCode === 8
    )
  ) {
    e.preventDefault();
    return false;
  } else {
    const numberInputted = String.fromCharCode(e.keyCode);
    const currentValue = String(e.target.value);
    const newValue = Number(currentValue + numberInputted);

    if (e.target.name === 'numberOfVehicles' && newValue > 100) {
      e.preventDefault();
      return false;
    } else if (e.target.name === 'numberOfPassengers' && newValue > 5000) {
      e.preventDefault();
      return false;
    }
  }
};