import React from 'react';
import Slider from 'react-slick';
import { Container } from 'react-bootstrap';

import ImageWithText from '../../../../Components/ImageWithText/ImageWithText';
import content from './content';
import galleryContent from './galleryContent'

import './Fleet.css';

const settings = {
  mobileFirst: true,
  dots: true,
  arrows: false
};

export default class Fleet extends React.Component {
  render() {
    const { firstSlide, secondSlide, thirdSlide, fourthSlide } = content;
    return (
      <Container className="fleet">
        <h1>Fleet</h1>
        <Slider {...settings}>
          <ImageWithText
            type="fleet"
            imgSrc={firstSlide.img}
            imgAlt={firstSlide.imgAlt}
            title={firstSlide.title}
            textContent={firstSlide.textContent}
            galleryContent={galleryContent[firstSlide.imgAlt]}
          />
          <ImageWithText
            type="fleet"
            imgSrc={secondSlide.img}
            imgAlt={secondSlide.imgAlt}
            title={secondSlide.title}
            textContent={secondSlide.textContent}
            galleryContent={galleryContent[secondSlide.imgAlt]}
          />
          <ImageWithText
            type="fleet"
            imgSrc={thirdSlide.img}
            imgAlt={thirdSlide.imgAlt}
            title={thirdSlide.title}
            textContent={thirdSlide.textContent}
            galleryContent={galleryContent[thirdSlide.imgAlt]}
          />
          <ImageWithText
            type="fleet"
            imgSrc={fourthSlide.img}
            imgAlt={fourthSlide.imgAlt}
            title={fourthSlide.title}
            textContent={fourthSlide.textContent}
            galleryContent={galleryContent[fourthSlide.imgAlt]}
          />
        </Slider>
      </Container>
    );
  }
}
