import React from 'react';
import Slider from 'react-slick';
import { Container, Row, Col, Image } from 'react-bootstrap';

import content from './content';

import './Clients.css';
import ImageWithText from '../../../../Components/ImageWithText/ImageWithText';

const settings = {
  mobileFirst: true,
  autoplay: true,
  autoplaySpeed: 5000
};

export default class Clients extends React.Component {
  render() {
    const { firstSlide, secondSlide, thirdSlide } = content;
    return (
      <Container className="clients">
        <Row>
          <Col>
            <h1>Clients</h1>
          </Col>
        </Row>
        <Row>
          <div className="img-container">
            <Image src="/assets/lenovo.svg" alt="lenovo logo" />
            <Image src="/assets/lenovo.svg" alt="lenovo logo" />
            <Image src="/assets/lenovo.svg" alt="lenovo logo" />
            <Image src="/assets/lenovo.svg" alt="lenovo logo" />
            <Image src="/assets/lenovo.svg" alt="lenovo logo" />
          </div>
        </Row>
        <Row>
          <Col>
            <Slider {...settings}>
              <ImageWithText
                type="clients"
                imgSrc={firstSlide.img}
                imgAlt={firstSlide.imgAlt}
                name={firstSlide.name}
                textContent={firstSlide.textContent}
              />
              <ImageWithText
                type="clients"
                imgSrc={secondSlide.img}
                imgAlt={secondSlide.imgAlt}
                name={secondSlide.name}
                textContent={secondSlide.textContent}
              />
              <ImageWithText
                type="clients"
                imgSrc={thirdSlide.img}
                imgAlt={thirdSlide.imgAlt}
                name={thirdSlide.name}
                textContent={thirdSlide.textContent}
              />
            </Slider>
          </Col>
        </Row>
      </Container>
    );
  }
}
