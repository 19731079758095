import React, { useState, useContext, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import { cardsFunction } from './utils';

import './ClientType.css';

/**
 * ClientType is the first step in the Quote flow
 * The data is structured as so:
 * {
 *  corporate: [...],
 *  wholesale: [...],
 *  wedding: [...]
 * }
 * So this simply narrows it down to the [...]'s
 */
export default function ClientType({ changeSection, Context }) {
  const { dispatch } = useContext(Context);

  const [finishedInputting, setAsFinished] = useState(false);
  // Specifies the selected radio button
  const [clientTypeSelection, changeClientTypeSelection] = useState(null);
  // Image src and alt state
  const [imgSrcSelected, changeImgSrcSelected] = useState(null);
  const [imgAltSelected, changeImgAltSelected] = useState(null);

  useEffect(() => {
    if (clientTypeSelection !== null) {
      // User can go to next page if a vehicle is clicked (other data has default values)
      if (finishedInputting === false) {
        setAsFinished(true);
      }
    }
  }, [clientTypeSelection]);

  return (
    <div className="client-type">
      <Form
        onSubmit={e => {
          e.preventDefault();
          dispatch({
            type: 'update',
            payload: {
              clientTypeSelection,
              clientTypeImgSrc: imgSrcSelected,
              clientTypeImgAlt: imgAltSelected
            }
          });
          // If type is wedding, there is only one option for the client
          // That is, Time Based pricing, first step is to get the location
          if (clientTypeSelection === 'Wedding') {
            changeSection('tripLocation');
          } else {
            changeSection('tripType');
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>What service are you looking for?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cardsFunction(
            clientTypeSelection,
            changeClientTypeSelection,
            changeImgSrcSelected,
            changeImgAltSelected
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            className="continue-btn"
            variant="main"
            disabled={finishedInputting === false}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Form>
    </div>
  );
}
