import React from 'react';
import { Button, Container, Row, Col, Image } from 'react-bootstrap';

import { ToggleContent } from '../../../../Hooks/utils';

import './Services.css';
import TermsConditions from '../TermsConditions/TermsConditions';

class Services extends React.Component {
  render() {
    return (
      <Container className="services">
        <Row className="title">
          <Col>
            <h1>Services</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={3} className="service">
            <Image
              src="/assets/private-charters-icon.svg"
              alt="private charters icon"
            />
            <h5>Private Charters</h5>
            <p>
              We offer private, customized charter services for companies,
              groups and individuals. Our charter services are fully customized
              to suit each group's individual transportation needs and can
              provide overnight or out-of-state trips to the Western states.
            </p>
          </Col>
          <Col sm={12} md={6} lg={3} className="service">
            <Image
              src="/assets/special-accommodations-icon.svg"
              alt="special accommodations icon"
            />
            <h5>Accommodations</h5>
            <p>
              We offer a wide range of special accommodations to cater to our
              guests' individual needs, including ADA-equipped buses,
              multilingual tour guides, Mobile Wi-Fi, public address systems and
              iPod/iPhone hookup, and "Meet and Greet" services to welcome and
              guide guests.
            </p>
          </Col>
          <Col sm={12} md={6} lg={3} className="service">
            <Image
              src="/assets/corporate-shuttles-icon.svg"
              alt="corporate shuttle icon"
            />
            <h5>Corporate Shuttles</h5>
            <p>
              We offer a variety of shuttle options to meet any organization's
              transportation needs. Our shuttle services include customized
              routes, company signage on vehicles and access to a personalized
              webpage with detailed route information and schedules.
            </p>
          </Col>
          <Col sm={12} md={6} lg={3} className="service">
            <Image src="/assets/transfer-icon.svg" alt="transfers icon" />
            <h5>Transfers</h5>
            <p>
              We offer point-to-point transfers for groups with as little as
              four passengers to large corporate teams requiring multi-day
              pickups and dropoffs. We also offer "Meet and Greet" services and
              on-site coordination to welcome guests and ensure a pleasant and
              stress-free experience.
            </p>
          </Col>
        </Row>
        <Row className="terms-conditions">
          <Col>
            <p>
              Read our{' '}
              <ToggleContent
                toggle={show => (
                  <Button
                    variant="link"
                    className="terms-conditions-btn"
                    onClick={show}
                  >
                    Terms and Conditions
                  </Button>
                )}
                content={hide => <TermsConditions hide={hide} />}
              />
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Services;
