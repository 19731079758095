import React, { useState, useContext } from 'react';
import { Alert, Modal, Image, Row, Col, Button } from 'react-bootstrap';

import {
  militaryToStandard,
  calculateQuote,
  createVehiclesContent,
  largeBoolean,
  sendEmailsPostData
} from './utils';
import { createModalHeader } from '../../utils';

import './Quote.css';

/**
 * The conclusion. The quote is calculated here,
 * and an email is sent to the user as well as the client.
 * Quote data is stored on the backend
 */
export default function Quote({ changeSection, Context }) {
  const { state } = useContext(Context);

  // Notification state after posting multiple times to the API
  const [showNotification, toggleShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState('');
  // Prevent user from being able to click submit repeatedly
  const [disabled, setDisabled] = useState(false);
  let startDate;
  // If this is not compatible, use the regular date string
  try {
    startDate = new Date(state.startDate)
      .toDateString()
      .split(' ')
      .slice(1)
      .join(' ');
  } catch (_) {
    /* should only fail w/ obscure operating systems */
    startDate = new Date(state.startDate);
  }
  // Get rid of the zero that is a default value of time inputs
  // there is no reason this should ever fail
  let { pickUpTime } = state;
  pickUpTime = militaryToStandard(pickUpTime);

  // Calculated Quote
  const quote = calculateQuote(state);
  state.quote = quote;

  const { firstName, amountOfPeople } = state;
  const tripType = state.tripTypeSelection || 'Wedding';
  // HTML -> <span>1 Eight person, </span><span>2 Thirty person ...</span>...
  const vehiclesHTML = createVehiclesContent(state, 'html');
  // String -> 1 Eight person, 2 Thirty Person...
  const vehiclesString = createVehiclesContent(state, 'string');
  state.vehiclesString = vehiclesString;
  const img = state.tripLocationImg || state.clientImgSrcSelected;
  const imgAlt = state.tripLocationAlt || state.clientImgAltSelected;

  return (
    <div className="quote">
      {createModalHeader(
        `Hi ${firstName}, here's your summary:`,
        changeSection,
        'details'
      )}
      <Modal.Body>
        <Row className="quote-in-dollars">
          <Col>
            <h6>QUOTE</h6>
            <h5>${quote}</h5>
          </Col>
        </Row>
        <div className="checkmarks-with-data-container">
          <Row className="checkmark-with-data">
            <Col>
              <Image src="/assets/checkmark.svg" alt="checkmark" />
              <p>{tripType}</p>
            </Col>
          </Row>
          <Row className="checkmark-with-data">
            <Col>
              <Image src="/assets/checkmark.svg" alt="checkmark" />
              <p>{vehiclesHTML}</p>
            </Col>
          </Row>
          <Row className="checkmark-with-data">
            <Col>
              <Image src="/assets/checkmark.svg" alt="checkmark" />
              <p>
                {amountOfPeople}&nbsp;
                {amountOfPeople === 1 ? 'person' : 'people'}
              </p>
            </Col>
          </Row>
          <Row className="checkmark-with-data">
            <Col>
              <Image src="/assets/checkmark.svg" alt="checkmark" />
              <p>{startDate}</p>
            </Col>
          </Row>
          <Row className="checkmark-with-data">
            <Col>
              <Image src="/assets/checkmark.svg" alt="checkmark" />
              <p>Pick up at {pickUpTime}</p>
            </Col>
          </Row>
          {largeBoolean(state) && (
            <Row className="checkmark-with-data">
              <Col>
                <Image src="/assets/checkmark.svg" alt="checkmark" />
                <p>{state.locationSelected}</p>
              </Col>
            </Row>
          )}
        </div>
        <div className="radio-btn-selection">
          <Row className="selection-img">
            <Col>{largeBoolean(state) && <Image src={img} alt={imgAlt} />}</Col>
          </Row>
        </div>
        <Row className="final-info">
          <Col>
            <p>
              Submit your quote and we'll get back to you with a confirmation.
            </p>
            <p>You can pay on the day of service!</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={disabled}
          className="continue-btn submit-btn"
          variant="main"
          onClick={() =>
            sendEmailsPostData(
              state,
              toggleShowNotification,
              setNotificationText,
              setDisabled
            )
          }
        >
          Submit
        </Button>
        {showNotification && (
          <Alert>
            {notificationText}
            <span role="img" aria-label="thumbs up">
              {' '}
              👍
            </span>
          </Alert>
        )}
      </Modal.Footer>
    </div>
  );
}
