import React from 'react';
import { Modal, Image, Button } from 'react-bootstrap';

export async function fetchData(dispatch) {
  let data;
  try {
    data = await fetch('/api/data', {
      method: 'GET',
      mode: 'cors',
      cache: 'default'
    });
  } catch (err) {
    console.log(err);
  }
  try {
    data = await data.json();
  } catch (err) {
    console.log(err);
  }

  dispatch({ type: 'update', payload: { data } });
}

export function createModalHeader(
  title,
  changeSection,
  location,
  complexOnClick
) {
  return (
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
      <button
        type="button"
        className="go-back"
        onClick={() => {
          if (location) changeSection(location);
          else complexOnClick();
        }}
      >
        <span aria-hidden="true">
          <Image src="/assets/left-arrow.svg" alt="go back button" />
        </span>
        <span className="sr-only">Go Back</span>
      </button>
    </Modal.Header>
  );
}

export function createModalFooter(finishedInputting) {
  return (
    <Modal.Footer>
      <Button
        type="submit"
        className="continue-btn"
        variant="main"
        disabled={finishedInputting === false}
      >
        Continue
      </Button>
    </Modal.Footer>
  );
}
