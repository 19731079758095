import React from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';

import GalleryModal from '../GalleryModal/GalleryModal';
import StartQuote from '../StartQuote/StartQuote';

import { ToggleContent } from '../../Hooks/utils';

import './ImageWithText.css';

export default function ImageWithText({
  type,
  imgSrc,
  imgAlt,
  title,
  textContent,
  name,
  galleryContent
}) {
  return (
    <Row className="image-with-text">
      {type === 'fleet' && (
        <React.Fragment>
          <Col sm={12} md={6} lg={8} className="img-col">
            <Image src={imgSrc} alt={imgAlt} />
          </Col>
          <Col sm={12} md={6} lg={4} className="text-col">
            <h3>{title}</h3>
            {textContent}
            <ToggleContent
              toggle={show => (
                <Button
                  variant="main"
                  onClick={show}
                  className="start-quote-margin"
                >
                  Start Quote
                </Button>
              )}
              content={hide => <StartQuote hide={hide} />}
            />
            <ToggleContent
              toggle={show => (
                <Button variant="white" onClick={show}>
                  See Vehicle
                </Button>
              )}
              content={hide => (
                <GalleryModal images={galleryContent} hide={hide} />
              )}
            />
          </Col>
        </React.Fragment>
      )}
      {type === 'clients' && (
        <React.Fragment>
          <Col sm={12} md={6} lg={7} className="text-col">
            {textContent}
            {name}
          </Col>
          <Col sm={12} md={6} lg={5} className="img-col">
            <Image src={imgSrc} alt={imgAlt} />
          </Col>
        </React.Fragment>
      )}
    </Row>
  );
}
