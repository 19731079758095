import React from 'react';
import { Modal } from 'react-bootstrap';

import './TermsConditions.css';

export default function TermsConditions({ hide }) {
  return (
    <Modal show={true} onHide={hide} className="terms-conditions">
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>
            Charges are based on either mileage or time, whichever is greater.
          </li>
          <li>Overtime hours are charged in half-hour increments.</li>
          <li>
            A fuel surcharge is applied to all quotes at a rate set
            periodically.
          </li>
          <li>
            Tolls, parking, taxes, fuel surcharge & entrance fees will be added
            to the charges.
          </li>
          <li>
            Unless otherwise indicated, rates do not include customary driver
            gratuities of 10-20% and may be paid directly to driver.
          </li>
          <li>
            A 10% gratuity will be added if the driver does not receive any
            gratuity at the end of a charter.
          </li>
          <li>
            Cancellations less than 72 hours prior to start of service will be
            charged $150.
          </li>
          <li>
            Cancellations less than 24 hours prior to start of service will be
            charged a 4-hour minimum rate.
          </li>
          <li>
            Cancellations at the pick-up spot or "no-shows" will be charged the
            full charter cost.
          </li>
          <li>
            A $300 or more cash cleaning deposit will be applied for dirtied
            buses, including liquid spillage.
          </li>
          <li>
            Driver Room must be provided for overnight trips otherwise room
            charges will be added.
          </li>
          <li>
            Transfers must be completed within 2 hours of spot time ordered or
            the hourly charge will apply for the excess hours.
          </li>
          <li>
            Additional service must be authorized by agent, or may be paid in
            cash.
          </li>
          <li>Rates subject to change without prior notice.</li>
          <li>We are not responsible for articles left in the vehicle.</li>
          <li>
            Drugs, smoking and alcoholic beverages are not allowed in or about
            the vehicle.
          </li>
          <li>
            Renters will be held responsible for all damages to the vehicles or
            its contents due to negligence, intentional misconduct or malicious
            mischief.
          </li>
          <li>
            We have the right to terminate/refuse service or evict anyone who
            threatens the safety of the passengers, the driver, the vehicle or
            other properties.
          </li>
          <li>
            The driver reserves the right not to take a vehicle to an unsafe or
            illegal location that could cause damage to the vehicle, property or
            people.
          </li>
          <li>
            Unpaid accounts 30 days and over from service date will be charged
            1% per month
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}
