import React from 'react';
import { Modal, Image } from 'react-bootstrap';

import './GalleryModal.css';

export default function GalleryModal({ images, hide }) {
  return (
    <Modal show={true} onHide={hide} className="gallery-modal">
      <Modal.Body>
        {images.map(({ img, imgAlt }, i) => (
          <Image key={i} src={img} alt="imgAlt" />
        ))}
      </Modal.Body>
    </Modal>
  );
}
