import React, { useState, useContext, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';

import { cardsFunction } from './utils';
import { createModalHeader, createModalFooter } from '../../utils';

import './TripType.css';

/**
 * TripType is the second step in the Quote flow
 * The data is now structured as so: (now inside a client type)
 * [{ object containing vehicle pricing data for each trip type }]
 * 
 * So this narrows it down to the trip type pricing but it is not so simple:
 * For example:
 *  {
      "id": "1",
      "priceBasis": "348",
      "additionalHour": "82",
      "outOfTown": "",
      "dayTrip": "",
      "airportTransferSFO": "169",
      "airportTransferOAK": "210",
      "airportTransferSJC": "262",
      "transfersSanJose": "",
      "transfersSantaCruzNapa": "",
      "transfersSacramento": "",
      "transfersMonterey": "",
      "name": "Eight Person Vehicle"
    }
 * priceBasis and additionalHour relate to a certain tripType
 * outOfTown it's own
 * dayTrip it's own
 * airportTransfer('s) need to be narrowed down out of the three options
 * transfers does as well
 * 
 * That is done in following components
 */
export default function TripType({ changeSection, Context }) {
  const { dispatch } = useContext(Context);

  const [finishedInputting, setAsFinished] = useState(false);

  // Specifies the selected list button
  const [tripTypeSelection, changeTripTypeSelection] = useState(null);

  useEffect(() => {
    if (tripTypeSelection !== null) {
      setAsFinished(true);
    }
  }, [tripTypeSelection]);

  return (
    <div className="trip-type">
      <Form
        onSubmit={e => {
          e.preventDefault();
          dispatch({
            type: 'update',
            payload: { tripTypeSelection }
          });
          changeSection('tripLocation');
        }}
      >
        {createModalHeader(
          'What kind of trip are you planning?',
          changeSection,
          'clientType'
        )}
        <Modal.Body>
          {cardsFunction(tripTypeSelection, changeTripTypeSelection)}
        </Modal.Body>
        {createModalFooter(finishedInputting)}
      </Form>
    </div>
  );
}
