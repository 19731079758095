import React from 'react';

export default {
  firstSlide: {
    img: '/assets/minibus.png',
    imgAlt: 'sedan',
    title: 'Sedan',
    textContent: (
      <React.Fragment>
        <p>
        Our limos and sedans are a stylish and effective option for transporting small 
        groups. San Francisco Minibus limousines will get your group where you need to 
        go in style and comfort.
        </p>

        <ul>
          <li>Up to 4 passengers (sedan) or 10 passengers (stretch limo)</li>
          <li>Spacious Seating</li>
          <li>Small Luggage Storage</li>
        </ul>
      </React.Fragment>
    )
  },
  secondSlide: {
    img: '/assets/black-minibus.png',
    imgAlt: 'van',
    title: 'Van',
    textContent: (
      <React.Fragment>
        <p>
        Our vans are great for accomplishing a variety of transportation tasks. Whether 
        you need luggage space or room for extra passengers, our vans are flexible 
        enough to meet most of your needs.
        </p>
        <ul>
          <li>Up to 8 passengers</li>
          <li>Captain Chairs and Row Seats</li>
          <li>Spacious Cabin</li>
          <li>Reading Lights</li>
          <li>Seatbelts</li>
          <li>Luggage Storage</li>
        </ul>
      </React.Fragment>
    )
  },
  thirdSlide: {
    img: '/assets/minibus.png',
    imgAlt: 'minibus',
    title: 'Minibus',
    textContent: (
      <React.Fragment>
        <p>
        Choosing a minibus is an effective option for transporting medium sized groups. 
        They are one of the most flexible vehicle choices in our fleet.
        </p>
        <ul>
          <li>Up to 35 passengers</li>
          <li>Overhead Reading Lights</li>
          <li>Overhead Storage Bins</li>
          <li>Luggage Compartment</li>
          <li>Individual Reclining and Bucket Seats</li>
          <li>Spacious Aisle</li>
          <li>PA System</li>
        </ul>
      </React.Fragment>
    )
  }, 
  fourthSlide: {
    img: '/assets/bus.png',
    imgAlt: 'motorCoach',
    title: 'Motorcoach',
    textContent: (
      <React.Fragment>
        <p>
        Our maxibus service is the best option for transporting large groups comfortably. 
        </p>
        <ul>
          <li>Up to 58 passengers</li>
          <li>Overhead Reading Lights</li>
          <li>Luggage Compartment</li>
          <li>Individual Reclining and Bucket Seats</li>
          <li>Spacious Aisle</li>
          <li>Restroom Onboard</li>
          <li>PA System</li>
          <li>TV Hookup</li>
        </ul>
      </React.Fragment>
    )
  }
};
