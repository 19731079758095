export function appReducer(state, action) {
  switch (action.type) {
    case 'reset': {
      return action.payload;
    }
    case 'update': {
      return Object.assign(state, action.payload);
    }
    default: {
      return state;
    }
  }
}
