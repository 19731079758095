import React from 'react';
export default {
  firstSlide: {
    textContent: (
      <p>
        Some stuff written Some stuff written Some stuff written Some stuff
        written Some stuff written i'm taun taun
      </p>
    ),
    name: <blockquote>Taun Taun, Lenovo</blockquote>,
    img: '/assets/taun-taun.jpg',
    imgAlt: 'taun taun'
  },
  secondSlide: {
    textContent: (
      <p>
        Some stuff written Some stuff written Some stuff written Some stuff
        written Some stuff written i'm taun taun 2
      </p>
    ),
    name: <blockquote>Taun Taun 2, Lenovo</blockquote>,
    img: '/assets/taun-taun.jpg',
    imgAlt: 'taun taun2'
  },
  thirdSlide: {
    textContent: (
      <p>
        Some stuff written Some stuff written Some stuff written Some stuff
        written Some stuff written and i'm 3!
      </p>
    ),
    name: <blockquote>Taun Taun 3, Lenovo</blockquote>,
    img: '/assets/taun-taun.jpg',
    imgAlt: 'taun taun3'
  }
};
