import React from 'react';
import { Form, Button, Col, Row, Image, Table } from 'react-bootstrap';

import './VehicleType.css';

/**
 * This simply takes a string and returns the number corresponding.
 * It is used to calculate if the user has the right amount of
 * vehicles based upon how many passengers they are taking.
 * @param {String} vehicle
 */
function switchToFindVehiclePeopleAmount(vehicle, vehicles) {
  switch (vehicle) {
    case vehicles[0].name:
      return 8;
    case vehicles[1].name:
      return 12;
    case vehicles[2].name:
      return 24;
    case vehicles[3].name:
      return 28;
    case vehicles[4].name:
      return 32;
    case vehicles[5].name:
      return 55;
    default:
      return 0;
  }
}

/**
 * When the user adds a vehicle they would like to rent
 * the choices are displayed with this function,
 * each choice has an image, alt, string of text specifying
 * the name, and a delete button if the user changes their mind.
 * @param {[{vehicle object}]} vehicleChoices
 * @param {Function} changeVehicleChoices
 * @param {Number} amountOfPeople
 * @param {Function} changeAmountOfPeople
 * @return <Table>...items</Table>
 */
export function showVehicleChoices(
  vehicleChoices,
  changeVehicleChoices,
  amountOfPeople,
  changeAmountOfPeople,
  vehicles
) {
  if (vehicleChoices.length === 0) return null;
  return (
    <Table responsive="sm" className="vehicle-show">
      <thead>
        <tr>
          <th>Vehicle</th>
          <th>Amount</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {vehicleChoices.map((name, i) => (
          <tr className="vehicle" key={i}>
            <td>{name}</td>
            {/* Need to keep track of where I am in the array */}
            <td className="amount">
              {switchToFindVehiclePeopleAmount(vehicleChoices[i], vehicles)}
            </td>
            <td className="delete">
              <Button
                type="button"
                variant="link"
                className="delete-button"
                onClick={() => {
                  // First find the first index of the element
                  const idx = vehicleChoices.findIndex(
                    vehicle => vehicle === name
                  );
                  let alteredArray = [];
                  // If it is zero then just slice from there
                  if (idx === 0) {
                    alteredArray = vehicleChoices.slice(
                      1,
                      vehicleChoices.length
                    );
                    // Otherwise do a non mutating form of splice (not that it matters)
                  } else {
                    alteredArray = vehicleChoices
                      .slice(0, idx)
                      .concat(
                        vehicleChoices.slice(idx + 1, vehicleChoices.length)
                      );
                  }
                  const amount = switchToFindVehiclePeopleAmount(
                    vehicleChoices[vehicleChoices.length - 1],
                    vehicles
                  );
                  changeAmountOfPeople(amountOfPeople + amount);
                  changeVehicleChoices(alteredArray);
                }}
              >
                <Image src="/assets/delete.svg" alt="delete icon" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
/**
 * Creates the options, when each option is clicked it is what is displayed
 * below the options (image and text)
 * @param {[{vehicle object}]} vehicleData Possible vehicles, set as options
 * @param {String} currentSelection Currently active (selected option)
 * @param {Function} changeCurrentSelection Alters currently active
 * @param {Function} changeImgSrcSelected Same
 * @param {Function} changeImgAltSelected Same
 * @return [option]
 */
function createOptions(
  vehicleData,
  changeCurrentSelection,
  changeImgSrcSelected,
  changeImgAltSelected
) {
  return vehicleData.map(({ name, id }, i) => (
    <option
      value={name}
      key={id}
      onClick={() => {
        changeCurrentSelection(name);
        const [imgSrc, imgAlt] = filterOptionData(vehicleData, name);
        changeImgSrcSelected(imgSrc);
        changeImgAltSelected(imgAlt);
      }}
    >
      {name}
    </option>
  ));
}

/**
 * Creates a wrapper around the options
 * @return HTML wrapper around options
 */
export function optionsFunction(
  vehicleData,
  currentSelection,
  changeCurrentSelection,
  changeImgSrcSelected,
  changeImgAltSelected
) {
  return (
    <fieldset>
      <Form.Group className="select-form-group">
        <Row>
          <Col>
            <select
              className="custom-select"
              multiple
              value={[currentSelection]}
              // Not sure the reason onChange is needed here
              onChange={() => null}
            >
              {createOptions(
                vehicleData,
                changeCurrentSelection,
                changeImgSrcSelected,
                changeImgAltSelected
              )}
            </select>
          </Col>
        </Row>
      </Form.Group>
    </fieldset>
  );
}

/**
 * Filters out the src and alt for the image related to the option
 * from the vehicle data
 */
function filterOptionData(vehicleData, currentSelection) {
  // Grab the data corresponding with the selection

  let filteredVehicleData = vehicleData.filter(
    radioBtnData => radioBtnData.name === currentSelection
  );
  if (filteredVehicleData.length) {
    filteredVehicleData = filteredVehicleData[0];
  }
  return [filteredVehicleData.img, filteredVehicleData.name];
}

/**
 * When an option is clicked the options corresponding image and text is shown
 * This shows those assets.
 * It also renders a plus button to add the specified vehicle as one in
 * which the user wants to rent
 * @param {String} currentSelection Currently selected option
 * @param {String} imgSrcSelected Same
 * @param {String} imgAltSelected Same
 * @param {[String]} vehicleChoices The vehicles that the user chooses to rent
 * @param {Function} changeVehicleChoices Alter the choices when plus is clicked
 * @param {Number || String} amountOfPeople A small calculation is done here,
 * to check if the user has enough vehicles (they cannot add more after a point)
 * @param {Function} changeAmountOfPeople Set's the calculation in state
 * @return HTML displaying the options image and corresponding text
 */
export function showOptionDataClicked(
  currentSelection,
  imgSrcSelected,
  imgAltSelected,
  vehicleChoices,
  changeVehicleChoices,
  amountOfPeople,
  changeAmountOfPeople,
  vehicles
) {
  return (
    <div className="option-selection">
      <Row>
        <Col />
      </Row>
      <Row className="selection-img-row">
        <Col xs={3}>
          <p>{currentSelection}</p>
        </Col>
        <Col xs={6}>
          <Image src={imgSrcSelected} alt={imgAltSelected} />
        </Col>
        <Col xs={3}>
          <Button
            type="button"
            disabled={amountOfPeople < 0}
            variant="link"
            className="add-button"
            onClick={() => {
              const amount = switchToFindVehiclePeopleAmount(
                currentSelection,
                vehicles
              );
              changeAmountOfPeople(amountOfPeople - amount);
              changeVehicleChoices(vehicleChoices.concat(currentSelection));
            }}
          >
            <Image src="/assets/plus.svg" alt="plus icon" />
            Add
          </Button>
        </Col>
      </Row>
    </div>
  );
}

/**
 * In the data coming from the backend, not all vehicles are
 * available based upon the trip type that the client is accessing
 * @param {Object} state
 * @return the available vehicles that the user can rent based upon tripType
 */
export function filterOutCorrectVehicles(state, vehicles) {
  const tripTypeSelection = state.tripTypeSelection;
  let eightPerson,
    twentyFourPerson,
    twentyEightPerson,
    thirtyTwoPerson,
    fiftyFivePerson;
  vehicles.forEach(({ name }, i) => {
    switch (i) {
      case 0:
        eightPerson = name;
        break;
      case 2:
        twentyFourPerson = name;
        break;
      case 3:
        twentyEightPerson = name;
        break;
      case 4:
        thirtyTwoPerson = name;
        break;
      default:
        fiftyFivePerson = name;
    }
  });

  return vehicles.filter(({ name }) => {
    // All trip types have time based pricing
    if (tripTypeSelection === 'Time Based (Four Hour Minimum)') {
      return true;
      // After they are more selective
    } else if (
      tripTypeSelection === 'Out of Town (10 Hour and 250 Mile Maximum)'
    ) {
      if (
        name === twentyFourPerson ||
        name === twentyEightPerson ||
        name === fiftyFivePerson
      ) {
        return true;
      }
    } else if (
      tripTypeSelection === 'Day Trip (Reno/Tahoe/Yosemite)' ||
      tripTypeSelection === 'City Transfer'
    ) {
      if (
        name === twentyFourPerson ||
        name === twentyEightPerson ||
        name === fiftyFivePerson ||
        name === thirtyTwoPerson
      ) {
        return true;
      }
    } else if (tripTypeSelection === 'Airport Transfer') {
      if (
        name === eightPerson ||
        name === twentyFourPerson ||
        name === fiftyFivePerson
      ) {
        return true;
      }
      // This should never run, it is likely delete-able
    } else if (tripTypeSelection === undefined) {
      return true;
    }
    return false;
  });
}
