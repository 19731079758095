import React from 'react';

/**
 * Converts an unreadable (input type of time) to one that is readable
 */
export function militaryToStandard(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set am/pm
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

/**
 * This calculates the quote.
 * @param {Object containing prices for calculation} state
 * @return Number that is the quote
 */
export function calculateQuote(state) {
  // First, preliminary logic prior to calculating the Quote
  let total = 0;
  let priceData = state.data;
  const vehicleChoices = state.vehicleChoices;
  // First go through vehicle choices, to easily get a number of each vehicle
  // as each vehicle has a different price associated
  const hashedVehicleChoices = hashStrings(vehicleChoices);
  const clientType = state.clientTypeSelection;
  const tripType = state.tripTypeSelection;
  // Corporate, wholesale, and wedding have different prices
  switch (clientType) {
    case 'Corporate':
      priceData = priceData.corporate;
      break;
    case 'Wholesale':
      priceData = priceData.wholesale;
      break;
    default:
      priceData = priceData.wedding;
      break;
  }

  // Here, the calculations begin
  // Client type is the first filter
  if (clientType === 'Corporate' || clientType === 'Wholesale') {
    // Then the trip type
    if (tripType === 'Time Based (Four Hour Minimum)') {
      // Amount of hours affects time based
      const amountOfHours = state.amountOfHours;
      priceData.forEach(vehiclePriceData => {
        // Go through each price data (which has vehicles and prices associated)
        // If the vehicle is in the hash then it is part of the calculation
        if (hashedVehicleChoices[vehiclePriceData.name]) {
          total =
            hashedVehicleChoices[vehiclePriceData.name] *
              Number(vehiclePriceData.priceBasis) +
            hashedVehicleChoices[vehiclePriceData.name] *
              (Number(vehiclePriceData.additionalHour) * (amountOfHours - 4));
        }
      });
    } else if (tripType === 'Out of Town (10 Hour and 250 Mile Maximum)') {
      // No hourly rate, solely a flat rate associated with each vehicle
      priceData.forEach(vehiclePriceData => {
        if (hashedVehicleChoices[vehiclePriceData.name]) {
          total +=
            hashedVehicleChoices[vehiclePriceData.name] *
            Number(vehiclePriceData.outOfTown);
        }
      });
    } else if (tripType === 'Day Trip (Reno/Tahoe/Yosemite)') {
      // No hourly rate, solely a flat rate associated with each vehicle
      priceData.forEach(vehiclePriceData => {
        if (hashedVehicleChoices[vehiclePriceData.name]) {
          total +=
            hashedVehicleChoices[vehiclePriceData.name] *
            Number(vehiclePriceData.dayTrip);
        }
      });
    } else if (tripType === 'Airport Transfer') {
      // No hourly rate, solely a flat rate associated with each vehicle and location
      priceData.forEach(vehiclePriceData => {
        if (hashedVehicleChoices[vehiclePriceData.name]) {
          if (state.locationSelected === 'Oakland') {
            total +=
              hashedVehicleChoices[vehiclePriceData.name] *
              Number(vehiclePriceData.airportTransferOAK);
          } else if (state.locationSelected === 'San Francisco') {
            total +=
              hashedVehicleChoices[vehiclePriceData.name] *
              Number(vehiclePriceData.airportTransferSFO);
          } else {
            total +=
              hashedVehicleChoices[vehiclePriceData.name] *
              Number(vehiclePriceData.airportTransferSJC);
          }
        }
      });
    } else {
      // This is for the City Transfer option
      // No hourly rate, solely a flat rate associated with each vehicle and location
      priceData.forEach(vehiclePriceData => {
        if (hashedVehicleChoices[vehiclePriceData.name]) {
          if (state.locationSelected === 'San José') {
            total +=
              hashedVehicleChoices[vehiclePriceData.name] *
              Number(vehiclePriceData.transfersSanJose);
          } else if (state.locationSelected === 'Monterey') {
            total +=
              hashedVehicleChoices[vehiclePriceData.name] *
              Number(vehiclePriceData.transfersMonterey);
          } else if (state.locationSelected === 'Sacramento') {
            total +=
              hashedVehicleChoices[vehiclePriceData.name] *
              Number(vehiclePriceData.transfersSacramento);
          } else {
            total +=
              hashedVehicleChoices[vehiclePriceData.name] *
              Number(vehiclePriceData.transfersSantaCruzNapa);
          }
        }
      });
    }
  } else {
    // This is for Weddings, only hourly rates
    const amountOfHours = state.amountOfHours;
    priceData.forEach(vehiclePriceData => {
      if (hashedVehicleChoices[vehiclePriceData.name]) {
        total =
          hashedVehicleChoices[vehiclePriceData.name] *
            Number(vehiclePriceData.priceBasis) +
          hashedVehicleChoices[vehiclePriceData.name] *
            (Number(vehiclePriceData.additionalHour) * (amountOfHours - 4));
      }
    });
  }
  return formatMoney(total);
}

/**
 * Counts how many vehicles the user is renting and how much of each
 * @param {[String]} arrayOfStrings Array of vehicle strings
 * @return {hashed vehicle data}
 */
function hashStrings(arrayOfStrings) {
  let hash = {};
  for (let i = 0; i < arrayOfStrings.length; i++) {
    if (!hash[arrayOfStrings[i]]) {
      hash[arrayOfStrings[i]] = 1;
    } else {
      hash[arrayOfStrings[i]] += 1;
    }
  }
  return hash;
}

/**
 * https://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-dollars-currency-string-in-javascript/40079019
 */
function formatMoney(amount) {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

/**
 * Creates HTML for the vehicles being rented, a bunch of spans
 * specifying the number of vehicles with the name, semantically.
 * @param {object of state} state
 * @return [span] Each span contains the name and number of vehicles
 */
export function createVehiclesContent(state, type) {
  const vehicleChoices = state.vehicleChoices;
  const hashedVehicleChoices = hashStrings(vehicleChoices);
  const hashKeys = Object.keys(hashedVehicleChoices);
  const hashKeysLen = hashKeys.length;
  const mappedVehicleChoices = hashKeys.map((name, i) => {
    // Quick regex for better semantic
    const amount = hashedVehicleChoices[name];
    const sedanRegex = /Sedan/gi;
    const vanRegex = /Van/gi;
    const minibusRegex = /Minibus/gi;
    const motorCoachRegex = /Motorcoach/gi;
    if (amount !== 1) {
      if (name.match(sedanRegex)) {
        name = name.replace(sedanRegex, "Sedan's");
      } else if (name.match(vanRegex)) {
        name = name.replace(vanRegex, "Van's");
      } else if (name.match(minibusRegex)) {
        name = name.replace(minibusRegex, "Minibus's");
      } else {
        name = name.replace(motorCoachRegex, "Motorcoach's");
      }
    }
    // TODO: fix - uses comma and an and when there are two elements
    // Second to last
    if (hashKeysLen - 2 > -1 && i === hashKeysLen - 2) {
      if (type === 'html') {
        return <span key={i}>{`${amount} ${name}, and `}</span>;
      } else {
        return `${amount} ${name}, and `;
      }
      // Last element
    } else if (hashKeysLen - 1 > -1 && i === hashKeysLen - 1) {
      if (type === 'html') {
        return <span key={i}>{`${amount} ${name}`}</span>;
      } else {
        return `${amount} ${name}`;
      }
    } else {
      if (type === 'html') {
        return <span key={i}>{`${amount} ${name}, `}</span>;
      } else {
        return `${amount} ${name}, `;
      }
    }
  });
  if (type === 'string') {
    return mappedVehicleChoices.join('');
  } else {
    return mappedVehicleChoices;
  }
}

/**
 * Large boolean to handle many options used several times in Quote.js
 */

export function largeBoolean(state) {
  return (
    state.clientTypeSelection !== 'Wedding' &&
    state.tripTypeSelection !== 'Day Trip (Reno/Tahoe/Yosemite)' &&
    state.tripTypeSelection !== 'Out of Town (10 Hour and 250 Mile Maximum)' &&
    state.tripTypeSelection !== 'Time Based (Four Hour Minimum)'
  );
}

/**
 * This handles both client and customer emails being sent
 * @param {Object} state
 * @param {String} url url to send email to
 * @return "failed" | "success"
 */
export async function sendEmail(state, url) {
  let emailResponse;
  try {
    emailResponse = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(state)
    });
  } catch (err) {
    console.log(err);
  }
  try {
    emailResponse = await emailResponse.json();
  } catch (err) {
    console.log(err);
  }
  if (!emailResponse.success) {
    return 'failed';
  } else {
    return 'success';
  }
}

/**
 * Handles posting quote data to the backend
 * @param {Object} state
 * @return "failed" | "success"
 */
export async function postQuote(state) {
  let postQuoteResponse;
  try {
    postQuoteResponse = await fetch('/api/quote', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify(state)
    });
  } catch (err) {
    console.log(err);
  }
  try {
    postQuoteResponse = await postQuoteResponse.json();
  } catch (err) {
    console.log(err);
  }
  if (!postQuoteResponse.success) {
    return 'failed';
  } else {
    return 'success';
  }
}

/**
 * When user submits quote, email is sent to the user and the client,
 * as well as a new Quote object being stored in the database
 * @param {Object} state
 * @param {Function} toggleShowNotification shows notification based on
 * the response from email sent to the user
 * @param {Function} setNotificationText  sets notification text based on
 * the response from email sent to the user
 * @return undefined
 */
export async function sendEmailsPostData(
  state,
  toggleShowNotification,
  setNotificationText,
  setDisabled
) {
  setDisabled(true);
  const customerEmailCheck = await sendEmail(state, '/api/email/customer');
  let notification = 'Email has been sent!';
  if (customerEmailCheck === 'failed') {
    notification =
      'Something went wrong, please check if you put the correct email.';
  }
  await sendEmail(state, '/api/email/client');
  await postQuote(state);
  toggleShowNotification(true);
  setNotificationText(notification);
}
