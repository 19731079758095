import React from 'react';
import { Form, Row, Col, Button, Image, Card } from 'react-bootstrap';

// Data corresponding to each client type
const cardsData = [
  {
    label: 'Corporate',
    name: 'corporate',
    id: 'corporate',
    imgSrc: '/assets/business.svg',
    imgAlt: 'business buildings'
  },
  {
    label: 'Wholesale',
    name: 'wholesale',
    id: 'wholesale',
    imgSrc: '/assets/wholesale.svg',
    imgAlt: 'full handcart'
  },
  {
    label: 'Wedding',
    name: 'wedding',
    id: 'wedding',
    imgSrc: '/assets/wedding.svg',
    imgAlt: 'man and wife getting married'
  }
];

/**
 * 
 * @param {String} currentSelection The currently selected card
 * @param {Function} changeCurrentSelection onClick the selection changes
 * @param {String} changeImgSrcSelected same
 * @param {String} changeImgAltSelected same
 * @return [Card] to display an icon with several words which a user can click
 */
function createCards(
  currentSelection,
  changeCurrentSelection,
  changeImgSrcSelected,
  changeImgAltSelected
) {
  return cardsData.map(({ label }, i) => {
    const [imgSrc, imgAlt] = filterCardsData(label);
    return (
      <Card
        key={i}
        className={`${currentSelection === label ? 'selected' : 'unselected'}`}
      >
        <Card.Body>
          <Button
            variant="link"
            onClick={() => {
              changeCurrentSelection(label);
              changeImgSrcSelected(imgSrc);
              changeImgAltSelected(imgAlt);
            }}
          >
            <Card.Text>{label}</Card.Text>
            <Image src={imgSrc} alt={imgAlt} />
          </Button>
        </Card.Body>
      </Card>
    );
  });
}

/**
 * Params are passed to the next function
 * This function solely creates the wrapper around the cards
 * @return Card wrapper html
 */
export function cardsFunction(
  currentSelection,
  changeCurrentSelection,
  changeImgSrcSelected,
  changeImgAltSelected
) {
  return (
    <fieldset>
      <Form.Group className="cards-form-group">
        <Row />
        <Row>
          <Col>
            <div className="card-deck">
              {createCards(
                currentSelection,
                changeCurrentSelection,
                changeImgSrcSelected,
                changeImgAltSelected
              )}
            </div>
          </Col>
        </Row>
      </Form.Group>
    </fieldset>
  );
}

/**
 * This goes through the data and filters out the correct img and label
 * @param {String} currentSelection 
 * @return [String (src for image), String (alt for image)]
 */
function filterCardsData(currentSelection) {
  // Grab the data corresponding with the selection
  let filteredRadioBtnData = cardsData.filter(
    radioBtnData => radioBtnData.label === currentSelection
  );
  if (filteredRadioBtnData.length) {
    filteredRadioBtnData = filteredRadioBtnData[0];
  }
  return [filteredRadioBtnData.imgSrc, filteredRadioBtnData.imgAlt];
}
