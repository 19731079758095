import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import './Shop.css';

class Shop extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  render() {
    return (
      <Row className="shop">
        <Col md={12}>
            <Card> 
              <div class="card-body">
                <iframe src="https://shop.meepcraft.com"></iframe>
              </div>
            </Card>
        </Col>
      </Row>
    );
  }
}

export default Shop;
