import React, { createRef } from 'react';
import scrollToComponent from 'react-scroll-to-component';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';

import MainNavbar from '../../Components/Navbar/Navbar';
import Services from './Sections/Services/Services';
import Fleet from './Sections/Fleet/Fleet';
import Clients from './Sections/Clients/Clients';
import Drivers from './Sections/Drivers/Drivers';
import Footer from '../../Components/Footer/Footer';

import './Landing.css';
import Video from './Sections/Video/Video';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  onScrollClick = section => {
    if (!section) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      scrollToComponent(this[section].current, {
        align: 'middle',
        duration: 1500
      });
    }
  };

  render() {
    return (
        <div class="col-sm-12">
                <div class="page-title home text-center col-md-8 offset-md-2">
                <p>Experience one of the most sophisticated virtual economies ever created in Minecraft. Settle your own town today and begin your adventure!</p>
                </div>
                <div class="hexagon-menu clear">
                <div class="hexagon-item">
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <Link to="/play" class="hex-content">
                        <span class="hex-content-inner">
                            <span class="icon">
                            <ion-icon name="logo-game-controller-b"></ion-icon>
                            </span>
                            <span class="title">Play</span>
                        </span>
                        <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path>
                        </svg>
                    </Link>
                </div>
                <div class="hexagon-item">
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <Link to="/" class="hex-content">
                    <span class="hex-content-inner disabled">
                        <span class="icon">
                            <ion-icon name="ios-stats"></ion-icon>
                        </span>
                        <span class="title">Stats</span>
                        <span>soon...</span>
                    </span>
                    <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path>
                    </svg>
                    </Link>
                </div>
                <div class="hexagon-item">
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <Link to="/" class="hex-content">
                    <span class="hex-content-inner disabled">
                        <span class="icon">
                            <ion-icon name="ios-map"></ion-icon>
                        </span>
                        <span class="title">Map</span>
                        <span>soon...</span>
                    </span>
                    <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path>
                    </svg>
                    </Link>
                </div>
                <div class="hexagon-item">
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <Link to="/shop" class="hex-content">
                    <span class="hex-content-inner">
                        <span class="icon">
                            <ion-icon name="ios-gift"></ion-icon>
                        </span>
                        <span class="title">Shop</span>
                    </span>
                    <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path>
                    </svg>
                    </Link>
                </div>
                <div class="hexagon-item">
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <a href="https://forum.meepcraft.com/pages/vote/" class="hex-content">
                    <span class="hex-content-inner">
                        <span class="icon">
                            <ion-icon name="ios-trophy"></ion-icon>
                        </span>
                        <span class="title">Vote</span>
                    </span>
                    <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path>
                    </svg>
                    </a>
                </div>
                <div class="hexagon-item">
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <Link to="/discord" class="hex-content">
                    <span class="hex-content-inner">
                        <span class="icon">
                            <ion-icon name="ios-chatbubbles"></ion-icon>
                        </span>
                        <span class="title">Discord</span>
                    </span>
                    <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path>
                    </svg>
                    </Link>
                </div>
                <div class="hexagon-item">
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="hex-item">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <a href="https://forum.meepcraft.com" class="hex-content">
                    <span class="hex-content-inner">
                        <span class="icon">
                            <ion-icon name="ios-book"></ion-icon>
                        </span>
                        <span class="title">Forums</span>
                    </span>
                    <svg viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="#1e2530"></path>
                    </svg>
                    </a>
                </div>
            </div>
        </div>
    );
  }
}

export default Landing;
