import React from 'react';
import { Form, Row, Col, ListGroup, Card } from 'react-bootstrap';

// Data corresponding to tripTypes (these are all the options possible)
const listGroupItemData = [
  { text: 'Time Based (Four Hour Minimum)' },
  { text: 'Out of Town (10 Hour and 250 Mile Maximum)' },
  { text: 'Day Trip (Reno/Tahoe/Yosemite)' },
  { text: 'Airport Transfer' },
  { text: 'City Transfer' }
];

/**
 * Creates a data wrapper, with list group items within holding the data
 * @param {String} tripTypeSelection currently selected trip
 * @param {Function} changeTripTypeSelection hook to change if another trip is selected
 * @return Card wrapper HTML
 */
export function cardsFunction(tripTypeSelection, changeTripTypeSelection) {
  return (
    <fieldset>
      <Form.Group className="cards-form-group images">
        <Row>
          <Col>
            <Card>
              <ListGroup variant="flush">
                {createListGroupItems(
                  tripTypeSelection,
                  changeTripTypeSelection
                )}
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Form.Group>
    </fieldset>
  );
}

/**
 * A collection of list group items containing data
 * @param {String} tripTypeSelection 
 * @param {Function} changeTripTypeSelection 
 * @return [ListGroup.Item]
 */
function createListGroupItems(tripTypeSelection, changeTripTypeSelection) {
  return listGroupItemData.map(({ text }, i) => (
    <ListGroup.Item
      key={i}
      onClick={() => changeTripTypeSelection(text)}
      active={tripTypeSelection === text}
    >
      {text}
    </ListGroup.Item>
  ));
}
