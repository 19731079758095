import React, { useState, useEffect, useContext } from 'react';
import { Modal, Form } from 'react-bootstrap';

import {
  showVehicleChoices,
  showOptionDataClicked,
  optionsFunction,
  filterOutCorrectVehicles
} from './utils';

import './VehicleType.css';
import { createModalFooter, createModalHeader } from '../../utils';

/**
 * As the data is shaped like this:
 *  *   *  {
      "id": "1",
      "priceBasis": "348",
      "additionalHour": "82",
      "outOfTown": "",
      "dayTrip": "",
      "airportTransferSFO": "169",
      "airportTransferOAK": "210",
      "airportTransferSJC": "262",
      "transfersSanJose": "",
      "transfersSantaCruzNapa": "",
      "transfersSacramento": "",
      "transfersMonterey": "",
      "name": "Eight Person Vehicle"
    }
  It means that each tripType has certain vehicles that the user
  is allowed to rent (notice empty strings for some options here)
  So, the vehicles that the user cannot use are left out here.
  And, using how many people they want to take on their trip, 
  the user can add as many vehicles they desire to reach the 
  necessary capacity, only allowed to go the next step when it is
  fulfilled.
 */
export default function VehicleType({ changeSection, Context }) {
  const { state, dispatch } = useContext(Context);
  // Vehicles from the backend (user can do CRUD operations with vehicle data)
  let vehiclesData = state.data.vehicles;
  let [vehicles, alterVehicles] = useState(state.data.vehicles || null);
  // setAsFinished is set to true when all form data is filled out
  const [finishedInputting, setAsFinished] = useState(false);
  // Specifies the selected radio button
  const [currentSelection, changeCurrentSelection] = useState(vehicles[0].name);
  // Image src and alt state
  const [clientImgSrcSelected, changeClientImgSrcSelected] = useState(vehicles[0].img);
  const [clientImgAltSelected, changeClientImgAltSelected] = useState(vehicles[0].name);
  // Capture number and type of vehicles
  const [vehicleChoices, changeVehicleChoices] = useState([]);

  // For calculating how many vehicles are necessary
  const [amountOfPeople, changeAmountOfPeople] = useState(state.amountOfPeople);

  // Same as componentDidMount
  useEffect(() => {
    alterVehicles(filterOutCorrectVehicles(state, vehicles));
  }, []);

  useEffect(() => {
    if (amountOfPeople < 0) {
      if (finishedInputting === false) {
        setAsFinished(true);
      }
    } else {
      setAsFinished(false);
    }
  }, [amountOfPeople]);

  return (
    <div className="vehicle-type">
      <Form
        onSubmit={() => {
          dispatch({
            type: 'update',
            payload: {
              totalPeople: amountOfPeople,
              vehicleChoices,
              currentSelection,
              clientImgSrcSelected,
              clientImgAltSelected,
              finishedVehicleTypePreviously: finishedInputting
            }
          });
          changeSection('details');
        }}
      >
        {createModalHeader(
          'Pick which vehicles you would like to take.',
          changeSection,
          'amountOfPeople'
        )}
        <Modal.Body>
          {optionsFunction(
            vehicles,
            currentSelection,
            changeCurrentSelection,
            changeClientImgSrcSelected,
            changeClientImgAltSelected
          )}
          {showOptionDataClicked(
            currentSelection,
            clientImgSrcSelected,
            clientImgAltSelected,
            vehicleChoices,
            changeVehicleChoices,
            amountOfPeople,
            changeAmountOfPeople,
            vehiclesData
          )}
          {showVehicleChoices(
            vehicleChoices,
            changeVehicleChoices,
            amountOfPeople,
            changeAmountOfPeople,
            vehiclesData
          )}
        </Modal.Body>
        {createModalFooter(finishedInputting)}
      </Form>
    </div>
  );
}
