import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import './Map.css';

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  render() {
    return (
        <Row className="map">
            <Col md={12}>
                <Card> 
                  <div class="card-body">
                    <h3>Map</h3>
                    <p class="card-text">Explore a dynamic map of the MeepCraft Towns world. The map allows you to view over one billion blocks in a top-down view. With a unique, custom generated terrain, this world is the perfect place to settle your town and begin your adventure. Players will only appear on the map if they are above ground.</p>
                   
                    <iframe src="https://map.meepcraft.com"></iframe>
                    <hr />
                    <a href="https://map.meepcraft.com" target="_blank">
                      <button type="button" class="btn btn-dark">Open in Fullscreen</button>
                    </a>
                  </div>
                </Card>
            </Col>
        </Row>
    );
  }
}

export default Map;
