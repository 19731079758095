export default {
  sedan: [
    {
      img: '/assets/temp-car-img-1.jpg',
      imgAlt: 'some car'
    },
    {
      img: '/assets/temp-car-img-2.jpg',
      imgAlt: 'some car 2'
    },
    {
      img: '/assets/temp-car-img-3.jpg',
      imgAlt: 'some car 3'
    }
  ],
  van: [
    {
      img: '/assets/temp-car-img-3.jpg',
      imgAlt: 'some car'
    },
    {
      img: '/assets/temp-car-img-1.jpg',
      imgAlt: 'some car 2'
    },
    {
      img: '/assets/temp-car-img-2.jpg',
      imgAlt: 'some car 3'
    }
  ],
  minibus: [
    {
      img: '/assets/temp-car-img-2.jpg',
      imgAlt: 'some car'
    },
    {
      img: '/assets/temp-car-img-3.jpg',
      imgAlt: 'some car 2'
    },
    {
      img: '/assets/temp-car-img-1.jpg',
      imgAlt: 'some car 3'
    }
  ],
  motorCoach: [
    {
      img: '/assets/temp-car-img-1.jpg',
      imgAlt: 'some car'
    },
    {
      img: '/assets/temp-car-img-2.jpg',
      imgAlt: 'some car 2'
    },
    {
      img: '/assets/temp-car-img-3.jpg',
      imgAlt: 'some car 3'
    }
  ]
};
