import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './Drivers.css';

export default class Drivers extends React.Component {
  render() {
    return (
      <Container className="drivers">
        <Row>
          <Col>
            <h1>Commercially Licensed Drivers</h1>
          </Col>
        </Row>
        {/* <Row className="img-content">
          <Col xs={12} sm={6} md={3}>
            <Image src="/assets/taun-taun.jpg" alt="taun taun the driver" />
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Image src="/assets/taun-taun.jpg" alt="taun taun the driver" />
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Image src="/assets/taun-taun.jpg" alt="taun taun the driver" />
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Image src="/assets/taun-taun.jpg" alt="taun taun the driver" />
          </Col>
        </Row> */}
        <Row className="text-content">
          <Col>
            <h3>We provide 24-hour customer service</h3>
            <p>
              San Francisco Minibus is{' '}
              <a href="https://www.transportation.gov/" target="_blank">
                US-DOT
              </a>{' '}
              {'&'}{' '}
              <a href="http://www.cpuc.ca.gov/" target="_blank">
                CA-PUC
              </a>{' '}
              licensed to operate in all states. We operate throughout the Bay
              Area from San Francisco down the peninsula including San Mateo,
              Foster City, Palo Alto all the way to the South Bay{' '}
              <u>Silicon valley</u> cities of Mountain View, Santa Clara, San
              Jose.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
