import React, { Component } from 'react';
import { Router, Switch, Route, Link } from 'react-router-dom';
import * as history from 'history';

import Landing from './Pages/Landing/Landing';
import Play from './Pages/Play/Play';
import Stats from './Pages/Stats/Stats';
import Map from './Pages/Map/Map';
import Shop from './Pages/Shop/Shop';
import Discord from './Pages/Discord/Discord';
import Vote from './Pages/Vote/Vote';
import Video from './Sections/Video/Video';

const createHistory = history.createBrowserHistory;
const browserHistory = createHistory();

class App extends Component {
  render() {
    return (
      <div class="dark">
        <div class="preloader active done">
            <div class="loading-mask"></div>
            <div class="loading-mask"></div>
            <div class="loading-mask"></div>
            <div class="loading-mask"></div>
            <div class="loading-mask"></div>
        </div>
        <div class="preview-wrapper">
        </div>
        <main class="site-wrapper">
            <div class="pt-table desktop-768">
              <div class="pt-tablecell page-home relative">
                  {/* 
                  <div class="overlay"></div>
                  */}
                  <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                          <Router history={browserHistory}>
                            <div class="logo text-center">
                                <Link to="/"><img src="assets/logo.png" alt=""/></Link>
                            </div>
                              <Switch>
                                <Route path='/play' render={props =>
                                <Play {...props} />
                                } />
                                <Route path='/map' render={props =>
                                <Map {...props} />
                                } />
                                <Route path='/stats' render={props =>
                                <Stats {...props} />
                                } />
                                <Route path='/shop' render={props =>
                                <Shop {...props} />
                                } />
                                <Route path='/discord' render={props =>
                                <Discord {...props} />
                                } />
                                <Route path='/vote' render={props =>
                                <Vote {...props} />
                                } />
                                <Route path='/' render={props =>
                                <Landing {...props} />
                                } />
                              </Switch>
                          </Router>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
            <Video />
        </main>
      </div>
    );
  }
}

export default App;
