import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import './Stats.css';

class Stats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      name: 'Fuzzlr',
      kills: 0, 
      balance: 0,
      lastSeen: '',
      town: '',
      nation: '', 
      rank: '', 
      deaths: 0,
      uuid: '',
      retName: 'Fuzzlr'
    };

    this.options = {
      method: 'GET',
      mode: 'cors', 
      crossDomain: true,
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      })
    };

    this.getUuid = this.getUuid.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getUuid();
  }

  handleChange = function(e) {
    this.setState({ 
      name: e.target.value 
    });
  }

  getUuid = () => {
    if ((this.state.name === undefined) || (this.state.name === '')) {
      return;
    }

    fetch(`https://cors-anywhere.herokuapp.com/https://api.mojang.com/users/profiles/minecraft/${this.state.name}`, this.options).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      
      return response.json();

    }).then((json) => {
      this.setState({
        uuid: json.id

      }, () => this.getData());
    
    }).catch((error) => {
      console.log('Looks like there was a problem: \n', error);
    });
  }

  getData = () => {
    fetch(`https://cors-anywhere.herokuapp.com/https://meepcraft.com/players/${this.state.name}`, this.options).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      
      return response.json();
    
    }).then((json) => {
      this.setState({
        retName: json.name,
        kills: json.kills, 
        balance: json.balance,
        lastSeen: json.last_seen,
        town: json.town,
        nation: json.nation, 
        rank: json.rank, 
        deaths: json.deaths
      });
    
    }).catch((error) => {
      console.log('Looks like there was a problem: \n', error);
    });
  }

  render() {
    return (
        <Row className="stats">
            <Col md={8} className="offset-md-2">
                <Card> 
                  <div class="card-body">
                    <h3>Stats</h3>
                    <p class="card-text">
                        Enter a Minecraft username to view statistics.
                    </p>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => this.handleChange(e)} />
                    </div>
                    <button type="button" class="btn btn-dark" onClick={() => this.getUuid()}>Submit</button>
                    <hr />
                    <p class="card-text">
                        <h5>{this.state.retName}</h5>
                        <Row>
                            <Col md={4} className="avatar-container">
                                <img src={`https://crafatar.com/renders/body/${this.state.uuid}`} alt="Player" />
                            </Col>
                            <Col md={8}>
                                <table class="table">
                                <tbody>
                                    <tr>
                                    <th scope="row">Rank</th>
                                    <td>{this.state.rank}</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">Last Seen</th>
                                    <td>{this.state.lastSeen}</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">Meebles</th>
                                    <td>{this.state.balance}</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">Town / Nation</th>
                                    <td>{this.state.town} / {this.state.nation}</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">Kills / Deaths</th>
                                    <td>{this.state.kills} / {this.state.deaths}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </Col>
                        </Row>
                    </p>
                  </div>
                </Card>
            </Col>
        </Row>
    );
  }
}

export default Stats;
