import React from 'react';
import { Form, Row, Col, Image, Button, FormControl } from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';

// Images and alts to render based upon only two options (airport or transfer)
const imagesData = {
  airport: [
    { src: '/assets/oakland.png', alt: 'Oakland' },
    { src: '/assets/san-francisco.png', alt: 'San Francisco' },
    { src: '/assets/san-jose.svg', alt: 'San José' }
  ],
  transfer: [
    { src: '/assets/san-jose.svg', alt: 'San José' },
    { src: '/assets/sacramento.png', alt: 'Sacramento' },
    { src: '/assets/santa-cruz.png', alt: 'Santa Cruz' },
    { src: '/assets/napa.png', alt: 'Napa' },
    { src: '/assets/monterrey.png', alt: 'Monterrey' }
  ]
};

/**
 * Creates a wrapper around columns of images with text that are clickable
 * @param {String} tripTypeSelection The selected trip type
 * @param {String} locationSelected The selected location (a city)
 * @param {Function} changeLocationSelected Alters location based upon click
 * @param {Function} changeImgSrcSelected Same
 * @param {Function} changeImgAltSelected Same
 * @return HTML Wrapper containing columns of data
 */
export function imagesFunction(
  tripTypeSelection,
  locationSelected,
  changeLocationSelected,
  changeImgSrcSelected,
  changeImgAltSelected
) {
  return (
    <fieldset>
      <Form.Group className="images">
        <Row>
          {createImageColumns(
            tripTypeSelection,
            locationSelected,
            changeLocationSelected,
            changeImgSrcSelected,
            changeImgAltSelected
          )}
        </Row>
      </Form.Group>
    </fieldset>
  );
}

/**
 * Same params as above
 * Creates Columns containing an image for a city, and corresponding text
 * @return [Col]
 */
function createImageColumns(
  tripTypeSelection,
  locationSelected,
  changeLocationSelected,
  changeImgSrcSelected,
  changeImgAltSelected
) {
  let data;
  if (tripTypeSelection === 'Airport Transfer') {
    data = imagesData.airport;
  } else {
    data = imagesData.transfer;
  }
  return data.map(({ src, alt }, i) => (
    <Col xs={12} sm={6} lg={4} key={i} className="img-col">
      <Button
        className={`${locationSelected === alt ? 'active' : ''}`}
        variant="link"
        onClick={() => {
          changeLocationSelected(alt);
          changeImgAltSelected(alt);
          changeImgSrcSelected(src);
        }}
      >
        <Image rounded src={src} alt={alt} />
        <p>{alt}</p>
      </Button>
    </Col>
  ));
}

/**
 * This creates the HTML along with the logic for a user to look up
 * addresses (targeted near San Francisco)
 * @param {Object} state Object of state
 * @param {Object} hooks Object of Hooks
 * @return <Container>...</Container>
 */
export function inputFunction(state, hooks, formData) {
  const { location, locationSelected, locationSelection } = state;
  const {
    changeLocation,
    toggleLocationSelected,
    changeLocationSelection
  } = hooks;
  const { controlId, describedBy, placeholder, label } = formData;

  const stopsSelected = state.stopsSelected;
  const changeStopsSelected = hooks.changeStopsSelected;

  return (
    <div className="input data">
      <Form.Group
        className="inputs-border location-input"
        controlId={controlId}
      >
        <Form.Label>{label}</Form.Label>
        {autocompleteInput(
          [location, locationSelected, locationSelection, stopsSelected],
          [
            changeLocation,
            toggleLocationSelected,
            changeLocationSelection,
            changeStopsSelected
          ],
          describedBy,
          controlId,
          placeholder
        )}
      </Form.Group>
      {/* {pickUpLocationSelected && (
        <h5 className="shadow rounded location-content first">
          {pickUpLocationSelection}
        </h5>
      )} */}
    </div>
  );
}

export function locationsSelectedFunction(state, hooks) {
  const {
    pickUpLocationSelected,
    pickUpLocationSelection,
    dropOffLocationSelected,
    dropOffLocationSelection,
    wasStopSelected,
    stopsSelected
  } = state;
  const {
    changePickUpLocationSelection,
    togglePickUpLocationSelected,
    changeDropOffLocationSelection,
    toggleDropOffLocationSelected,
    changeStopsSelected,
    toggleWasStopSelected
  } = hooks;

  return (
    <div className="locations-selected">
      <div className="location-selected shadow rounded">
        <h5>
          <u>Pick up at:</u>
        </h5>
        {pickUpLocationSelected && (
          <div className="location">
            <h6 className="location-content">{pickUpLocationSelection}</h6>
            {closeButton([changePickUpLocationSelection, togglePickUpLocationSelected], '')}
          </div>
        )}
      </div>
      <div className="location-selected shadow rounded">
        <h5>
          <u>Stops:</u>
        </h5>
        {wasStopSelected &&
          stopsSelected.map((stop, i) => (
            <div className="location" key={i}>
              <h6 className="location-content">{stop}</h6>
              {closeButton([changeStopsSelected, toggleWasStopSelected], stop, stopsSelected)}
            </div>
          ))}
      </div>
      <div className="location-selected shadow rounded">
        <h5>
          <u>Drop off at:</u>
        </h5>
        {dropOffLocationSelected && (
          <div className="location">
            <h6 className="location-content">{dropOffLocationSelection}</h6>
            {closeButton([changeDropOffLocationSelection, toggleDropOffLocationSelected], '')}
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * A close button attached to each location
 *
 * For drop off and pick up the data can only be a
 * string meaning that all that is necessary to make
 * it equal to an empty string
 *
 * It is slightly more confusing for stops as this is
 * an array of strings meaning that the data must be
 * filtered out of the array
 *
 * @param hooks hook associated with the data
 *     The first hook relates to removing the item
 *     The second hook relates to letting the component know it is falsy
 * @param currentStop boolean value to check if it is stops (array)
 * @param stopsSelected array of stops to be filtered (deleted from array)
 * 
 * @return Close button with logic
 */
function closeButton(hooks, currentStop, stopsSelected) {
  return (
    <button
      type="button"
      className="close"
      aria-label="Close"
      onClick={() => {
        if (currentStop) {
          hooks[0](stopsSelected.filter(stop => stop !== currentStop));
        } else {
          hooks[0]('');
          hooks[1](false);
        }
      }}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  );
}

// Biases search to be around San Francisco
const searchOptions = {
  location:
    window.google && new window.google.maps.LatLng(37.773972, -122.431297),
  radius: 2000,
  types: ['address']
};

/**
 * This creates an input hooked up the Google Maps Places API which
 * allows a user to look up locations. It autocomplete's the users
 * search so that they can easily find what they are looking for.
 * @param {Object} state
 * @param {Object} hooks
 * @param {String} name
 * @param {String} describedBy
 * @param {String} placeholder
 * @return Input with search logic
 */
function autocompleteInput(state, hooks, name, describedBy, placeholder) {
  const location = state[0];
  const changeLocation = hooks[0];
  return (
    <PlacesAutocomplete
      value={location}
      onChange={text => {
        changeLocation(text);
      }}
      onSelect={(address, checkForEnter) =>
        handleSelect(address, hooks, checkForEnter, state)
      }
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <React.Fragment>
          <FormControl
            type="search"
            name={name}
            aria-label={name}
            aria-describedby={describedBy}
            placeholder={placeholder}
            className="location-search-input"
            {...getInputProps()}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )}
    </PlacesAutocomplete>
  );
}

/**
 * This function simply alters state, which is only done when the user chooses
 * one of the options that the Google API generates
 * @param {*} address
 * @param {*} hooks
 * @param {String | null} checkForEnter  Is null when user clicks enter rather
 * then choosing an option generated by the API, to prevent a user from inputting
 * an address that doesn't exist, as this function automatically runs when enter
 * is pressed
 * @return undefined (solely used to call hooks and alter state)
 */
function handleSelect(address, hooks, checkForEnter, state) {
  if (checkForEnter !== null) {
    const [
      changeLocation,
      toggleSelected,
      changeSelection,
      changeStopsSelected
    ] = hooks;
    changeLocation(address);
    changeSelection(address);
    toggleSelected(true);
    if (changeStopsSelected) {
      // at this time the fourth item in the array is the stops array
      changeStopsSelected(state[3].concat(address));
      changeLocation('');
    }
  }
}
